import React, { Component } from 'react'

import Footer from './../Component/FooterComponent/FooterComponent'


import WhoWeAre from '../Component/HomePageComponent/whoweare/whoweare'
import Houston from '../Component/HomePageComponent/houston/houston'
import HumanTouch from '../Component/HomePageComponent/humantouch/humantouch'
import BreakingEree from '../Component/HomePageComponent/breakingfree/breakingfree'
import DataEverywhere from '../Component/HomePageComponent/dataeverywhere/dataeverywhere'
import OurFuture from '../Component/HomePageComponent/ourfuture/ourfuture'
import WhatWeBelieve from '../Component/HomePageComponent/whatwebelieve/whatwebelieve'
import Client from '../Component/HomePageComponent/client/client'
import Ourlocation from '../Component/HomePageComponent/ourlocation/ourlocation'


import Navbar from './../Component/NavbarComponent/NavbarComponent'


import $ from 'jquery'
import './../jquery.pagepiling'
import './../jquery.pagepiling.css'


import AOS from 'aos'
import '../../node_modules/aos/dist/aos.css'


class HomePage extends Component {
    componentDidMount(){
        $(document).ready(function() {
            var $isAnimatedSecond = $('.sixthComponentfadein')
            var $isAnimateSecondanimate2=$('.animatefadindown')
    
    
            var $isAnimatedThird = $('.thirdComponentfadein')
            var $isAnimateThirdanimate2=$('.thirdfadindown')
    
    
            var $isAnimatedFourth = $('.FourthComponentfadein')
            var $isAnimateFourthanimate2=$('.Fourthfadindown')
    
    
            var $isAnimatedFifth = $('.FifthComponentfadein')
            var $isAnimateFifthanimate2=$('.Fifthfadindown')
    
            var $isAnimatedsixth = $('.sixthComponentfadeinLeft')
            var $isAnimatesixthanimate2=$('.sixthfadindown')
    
            var $isAnimatedseventh = $('.seventhComponentfadeinLeft')
            var $isAnimatesevenththanimate2=$('.seventhfadindown')
    
    
            var $isAnimatedeight = $('.eightComponentfadeinLeft')
            var $isAnimateeightimate2=$('.eightfadindown')
    
            var $isAnimatednine = $('.nineComponentfadeinLeft')
            var $isAnimatenineimate2=$('.ninefadindown')
    
            $('#pagepiling').pagepiling({
              navigation:false,
              afterRender: function(){
                new  AOS.init();
           }, 
             onLeave: function(index, nextIndex, direction) {
              // console.log(index)
              // console.log(nextIndex)
                if( index === 1) { 
                     $isAnimatedSecond.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '1s');
                    $isAnimateSecondanimate2.addClass('animate__animated animate__fadeInUp').css('animation-delay', '1s'); 
                }else if(index === 2){
                    $isAnimatedThird.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                     $isAnimateThirdanimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 3){
                  $isAnimatedFourth.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                  $isAnimateFourthanimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 4){
                  $isAnimatedFifth.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                  $isAnimateFifthanimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 5){ 
                  $isAnimatedsixth.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                  $isAnimatesixthanimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 6){ 
                  $isAnimatedseventh.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                  $isAnimatesevenththanimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 7){ 
                 $isAnimatedeight.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                 $isAnimateeightimate2.addClass('animate__animated animate__fadeInUp'); 
                }else if(index === 8){ 
                 $isAnimatednine.addClass('animate__animated animate__fadeInLeft').css('animation-delay', '0.3s');
                 $isAnimatenineimate2.addClass('animate__animated animate__fadeInUp'); 
                }
           },
           afterLoad: function(anchorLink, index){
             if(index>1){
               $('#pp-nav').removeClass('custom');
             }else{
               $('#pp-nav').addClass('custom');
             }
           }
          
     });     
        })
    }

    render() {
    
        return (
          <>
         <Navbar  />
            <div id="pagepiling">
                <div className="section sec1"> 
                <WhoWeAre />
                </div>
                <div className="section sec2" id="section2"> 
                <Houston />
                </div>
                <div className="section sec3" id="section3"> 
                <HumanTouch />
                </div>
                 <div className="section sec4" id="section4"> 
                 <BreakingEree />
                </div>
                <div className="section sec5" id="section5"> 
                <DataEverywhere />
                </div>
                <div className="section sec6" id="section6"> 
                <OurFuture />
                </div>
                <div className="section sec7" id="section7"> 
                <WhatWeBelieve  />
                </div>
                <div className="section sec8" id="section8"> 
                <Client />
                </div>
                <div className="section sec8" id="section9"> 
                <Ourlocation />
                </div>
            </div>
           
            <Footer />
          </>
        )
    }
}

export default HomePage
