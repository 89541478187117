import React from 'react'
import './client.css'

function client() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background ">
            <source src="/finalvideos/8.mp4" type="video/mp4"  />
        </video>
        <div className="videoeight Eightlayertxt ">
            <div className="HeaderNumber eightfadindown">08</div>
            <div className="eightHeader_definition eightComponentfadeinLeft">The technology we work with</div>
            <div className="border_bottom_empty_div eightfadindown" ></div>
            <div className="row sl eightfadindown">
                <div className="col-md-2 col-lg-1 start">
                    <div className="odd_1">
                        <div class="img_back"><img src="/Group 627.png" className="img-fluid" alt="" /></div>
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt="" /></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1">
                    <div class="even">
                        <div class="img_back"> <img src="/Group 626.png" className="img-fluid" alt="" /></div>
                        <div class="img_back">  <img src="/clientlogoicon/Litmus.svg" className="img-fluid" alt="" /> </div>
                        <div class="img_back"> <img src="/clientlogoicon/Diamondicon.svg" className="img-fluid" alt="" /></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1">
                    <div class="odd_1 reducemargintop">
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt="" /></div>
                        <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1">
                    <div class="even">
                        <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                        <div class="img_back"><img src="/clientlogoicon/logo2.png" className="img-fluid" alt="" /></div>
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt="" /></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1 hide_smallscreen hide_medium">
                    <div class="odd_1">
                        <div class="img_back"><img src="/clientlogoicon/figma_icon.svg" className="img-fluid"  alt=""/></div>
                        <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1 hide_smallscreen">
                    <div class="even">
                    <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                    <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt="" /></div>
                    <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1 hide_smallscreen hide_medium">
                    <div class="odd_1">
                    <div class="img_back">  <img src="/clientlogoicon/Litmus.svg" className="img-fluid" alt="" /> </div>
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid"  alt=""/></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1 hide_smallscreen hide_medium">
                    <div class="even">
                        <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                        <div class="img_back"><img src="/images/untitled folder/dummy_img.svg" className="img-fluid" alt=""/></div>
                        <div class="img_back"><img src="/clientlogoicon/logo2.png" className="img-fluid" alt="" /></div>
                    </div>
                </div>
                
                <div class="col-md-2  col-lg-1 hide_smallscreen hide_medium">
                    <div class="odd_1">
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt=""/></div>
                        <div class="img_back"><img src="/images/untitled folder/LoopEdge.svg" className="img-fluid" alt=""/></div>
                    </div>
                </div>
                
                <div class="col-lg-1 hide_smallscreen hide_medium">
                    <div class="even">
                        <div class="img_back"><img src="/clientlogoicon/cloud_icon.svg" className="img-fluid" alt=""/></div>
                        <div class="img_back"><img src="/clientlogoicon/figma_icon.svg" className="img-fluid"  alt=""/></div>
                        <div class="img_back"><img src="/images/untitled folder/Rectangle 56.png" className="img-fluid" alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
        </>
   
    )
}

export default client
