import React from 'react'
import './ourlocation.css'

function ourlocation() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background">
            <source src="/finalvideos/9.mp4" type="video/mp4"  />
        </video>
        <div className="videoninth ninthlayertxt">

            <div className="HeaderNumber ninefadindown">09</div>

            <div className="Header_definition nineComponentfadeinLef">Our Locations</div>

            <div className="border_bottom_empty_div ninefadindown"></div>

            <div className="row cont_add" style={{textAlign:"left"}}>
                <div>

                    <h5 className="addressheading">Jupiter Brothers Data Science Pvt Ltd</h5>
                    
                    <p className="addresspara">2/44,2/45, Maraneri Post, Sivakasi 626124 Virudhunagar District,<p>Tamilnadu. India.</p></p>
                    <p className="addresspara">Email:info@jupiterbrothers.com</p>
                    
                    {/* <div className="Location_sub_divs">
                      <div className="Location2">
                            <h5 style={{fontSize:"15px"}}>Australia</h5>
                        </div>
                        <div className="Location4">
                            <h5 style={{fontSize:"15px"}}>Canada</h5>
                        </div>
                        <div className="Location1">
                            <h5 style={{fontSize:"15px"}}>India</h5>
                        </div>
                        <div className="Location3">
                            <h5 style={{fontSize:"15px"}}>UAE</h5>
                        </div>
                    </div> */}
               
                </div>
                
             
            </div>
        </div>
    </>

    )
}

export default ourlocation
